import {
  type SearchTypingStepParams,
  type HomeStepParams,
  type SearchStepParams,
  type PreviewStepParams,
  type SearchedPreviewStepParams,
} from './params'

export const FeedStepKeys = [
  'home', // 홈
  'searchTyping', // 검색 인풋 클릭시 검색 타이핑 화면
  'search', // 검색 화면
  'preview', // 미리보기 화면
  'searchedPreview', // search step에서의 preview
  'externalPage', // 로컬프로필 홈과 같은 외부 페이지
] as const

export const OtherStepKeys = [] as const
export const LocalStepKeys = [...FeedStepKeys, ...OtherStepKeys] as const

export type FeedStepKeys = (typeof FeedStepKeys)[number]
export type OtherStepKeys = (typeof OtherStepKeys)[number]
export type LocalMapStepKeys = (typeof LocalStepKeys)[number]

type HomeStep = {
  step: 'home'
  params: HomeStepParams
}

type SearchTypingStep = {
  step: 'searchTyping'
  params: SearchTypingStepParams
}

type SearchStep = {
  step: 'search'
  params: SearchStepParams
}

type PreviewStep = {
  step: 'preview'
  params: PreviewStepParams
}

type SearchedPreviewStep = {
  step: 'searchedPreview'
  params: SearchedPreviewStepParams
}

type externalPageStep = {
  step: 'externalPage'
  params: {
    localProfileId: string
  }
}

export type LocalMapSteps =
  | HomeStep
  | SearchTypingStep
  | SearchStep
  | PreviewStep
  | SearchedPreviewStep
  | externalPageStep
